'use client';

import { ReactNode, useState } from 'react';

interface WebinarsCollapseProps {
  preview: ReactNode;
  rest: ReactNode;
  labelExpand: string;
  labelCollapse: string;
}
export default function WebinarsCollapse({
  preview,
  rest,
  labelExpand,
  labelCollapse,
}: WebinarsCollapseProps) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="flex flex-col items-center gap-4">
      {preview}
      {expanded && rest}
      <button
        className="text-sm text-magazine-500"
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? labelCollapse : labelExpand}
      </button>
    </div>
  );
}
