'use client';

import Image from 'next/image';
import { useRef } from 'react';
import { useTween } from 'react-use';

import StarSVG from 'public/static/webinar/star.svg';

const lerp = (start: number, end: number, t: number) => {
  return (1 - t) * start + t * end;
};

interface CounterProps {
  count: number;
  label: string;
  icon: 'participants' | 'business' | 'webinar';
}
export default function Counter({ count, label, icon }: CounterProps) {
  const t = useTween('quadratic', 1500);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const currentCount = lerp(0, count, t);

  const iconClasses =
    'absolute left-0 top-0 h-auto -translate-x-1/2 -translate-y-1/2';
  const renderIcon = () => {
    switch (icon) {
      case 'business':
        return (
          <Image
            src="/static/webinar/partners.svg"
            width={104}
            height={89}
            alt=""
            className={iconClasses}
          />
        );
      case 'participants':
        return (
          <Image
            src="/static/webinar/ios-people.svg"
            width={55}
            height={35}
            alt=""
            className={iconClasses}
          />
        );
      case 'webinar':
        return (
          <Image
            src="/static/webinar/notebook.svg"
            width={55}
            height={41}
            alt=""
            className={iconClasses}
          />
        );
    }
  };

  return (
    <div
      ref={wrapperRef}
      className="relative flex flex-col items-center justify-center rounded-3xl bg-white pb-3 pt-8 lg:pb-6 lg:pt-12 dark:bg-dark-700"
    >
      <StarSVG
        width={104}
        height={89}
        className="absolute left-0 top-0 -translate-x-1/2 -translate-y-1/2"
      />
      {renderIcon()}
      <h4 className="mb-6 text-2xl font-bold text-magazine-500 lg:text-5xl">
        {currentCount.toFixed(0)}
      </h4>

      <p className="text-sm text-gray-650">{label}</p>
    </div>
  );
}
